import React, { useRef, useState } from "react";
import SectionHeader from "../components/sectionHeader";
import Layout from "../components/layout";
import downArrow from "../assets/icons/icon-down-arrow.png";
import enrolIcon from "../assets/icons/icon-enrol.png";

import "../components/enrol/enrol.scss";
import { Fade } from "react-reveal";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import OnlineFeatures from "../components/features/OnlineFeatures";

import FeaturesBackgroundBanner from "../components/features/FeaturesBackgroundBanner";

//import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Nav } from "react-bootstrap";

import "../components/features/moreFeaturesLinksfeodaflow.scss";

import EngageFeatures from "../components/features/EngageFeatures";
import KeyFeatures from "../components/enrol/KeyFeatures";

function Online() {
  const onlinePageData = useStaticQuery(graphql`
    query {
      allStrapiOnlinePage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }
          MoreFeaturesLinks {
            Links {
              LinkTo
              Title
              id
            }
            Title
            TitleHighlight
            id
          }
          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
      allStrapiFeodaflowPage {
        nodes {
          PageHeader {
            Image {
              publicURL
            }
            TitleHighlight
            Title
            Content
            id
          }
        }
      }

      allStrapiEngagePage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }
          MoreFeaturesLinks {
            Links {
              LinkTo
              Title
              id
            }
            Title
            TitleHighlight
            id
          }
          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            TitleHighlight
            Title
            id
          }
          PageTitle
        }
      }
      allStrapiEnrolPage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
            }
            Title
          }
          MoreFeaturesLinks {
            Links {
              LinkTo
              Title
              id
            }
            Title
            TitleHighlight
            id
          }
          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
          }
          PageTitle
        }
      }
    }
  `);

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log("Reached bottom");
        //navigate('/Online');
      }
    }
  };

  const pageData = onlinePageData.allStrapiOnlinePage.nodes[0];
  const feodapageData = onlinePageData.allStrapiFeodaflowPage.nodes[0];
  const engagepageData = onlinePageData.allStrapiEngagePage.nodes[0];
  const enrolpageData = onlinePageData.allStrapiEnrolPage.nodes[0];
  console.log(feodapageData);
  return (
    <Layout>
      <Helmet defer={false}>
        <title>{pageData.PageTitle}</title>
      </Helmet>
      {/* Newly added */}
      <Container>
        <Row className="enrol-hero">
          <Col md>
            <Fade left>
              <div>
                <SectionHeader icon={enrolIcon} title={""} />
                <h1>
                  {feodapageData.PageHeader[0].Title}
                  <strong>{feodapageData.PageHeader[0].TitleHighlight}</strong>
                </h1>
                <ReactMarkdown source={feodapageData.PageHeader[0].Content} />

                <img src={downArrow} width="60" alt="icon"></img>
              </div>
            </Fade>
          </Col>

          <Col md className="hero-image">
            <img
              src={feodapageData.PageHeader[0].Image.publicURL}
              className="drop-shadow xyz-in"
              alt="process"
              xyz="fade right stagger delay-4 duration-10"
              width="600"
            ></img>
          </Col>
        </Row>
      </Container>

      {/* Newly added */}
      <section id="onlinesection">
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    title={pageData.PageHeader[0].Title}
                    highlight={pageData.PageHeader[0].TitleHighlight}
                    text={pageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown source={pageData.PageHeader[0].Content} />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={pageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <OnlineFeatures data={pageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section id="engagesection" STYLE={"display:none"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    title={engagepageData.PageHeader[0].Title}
                    highlight={engagepageData.PageHeader[0].TitleHighlight}
                    text={engagepageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown
                    source={engagepageData.PageHeader[0].Content}
                  />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={engagepageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <EngageFeatures data={engagepageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section id="enrolsection" STYLE={"display:none"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    //icon={enrolIcon}
                    title={enrolpageData.PageHeader[0].Title}
                    highlight={enrolpageData.PageHeader[0].TitleHighlight}
                    text={enrolpageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown source={enrolpageData.PageHeader[0].Content} />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={enrolpageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <KeyFeatures data={enrolpageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section className="feature-links">
        <Nav>
          <button
            id="onlinebtn"
            onClick={Togglefunconline}
            className="button active1"
          >
            Online
          </button>
          <button id="engagebtn" onClick={Togglefuncengage} className="button">
            Engage
          </button>
          <button id="enrolbtn" onClick={Togglefuncenrol} className="button">
            Enrol
          </button>
        </Nav>
      </section>

      <FeaturesBackgroundBanner />
    </Layout>
  );
}

function Togglefunconline() {
  if (
    document.getElementById("onlinesection") &&
    document.getElementById("engagesection") &&
    document.getElementById("enrolsection")
  ) {
    const targetDiv1 = document.getElementById("onlinesection");
    const targetDiv2 = document.getElementById("engagesection");
    const targetDiv3 = document.getElementById("enrolsection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "block";
    targetDiv2.style.display = "none";
    targetDiv3.style.display = "none";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("onlinebtn").classList.add("active1");
  }
}
function Togglefuncengage() {
  if (
    document.getElementById("onlinesection") &&
    document.getElementById("engagesection") &&
    document.getElementById("enrolsection")
  ) {
    const targetDiv1 = document.getElementById("onlinesection");
    const targetDiv2 = document.getElementById("engagesection");
    const targetDiv3 = document.getElementById("enrolsection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "none";
    targetDiv2.style.display = "block";
    targetDiv3.style.display = "none";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("engagebtn").classList.add("active1");
  }
}

function Togglefuncenrol() {
  if (
    document.getElementById("onlinesection") &&
    document.getElementById("engagesection") &&
    document.getElementById("enrolsection")
  ) {
    const targetDiv1 = document.getElementById("onlinesection");
    const targetDiv2 = document.getElementById("engagesection");
    const targetDiv3 = document.getElementById("enrolsection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "none";
    targetDiv2.style.display = "none";
    targetDiv3.style.display = "block";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("enrolbtn").classList.add("active1");
  }
}
export default Online;
